import { sendUtagEvent } from './utag_events'

// eslint-disable-next-line
;(function ($) {
  const initialization = true
  const TAB_INDEX_MAP_SHOWN_BY_DEFAULT = 1

  if (typeof IB === 'undefined') {
    window.IB = {}
  } else if (IB.utag_eec_events !== undefined) {
    return
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // -----------------------------------------------------------GENERIC OBSERVERS --------------------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  // Observers definition
  const productImpressionObserver = new IntersectionObserver(
    function (entries) {
      for (let i = 0; i < entries.length; ++i) {
        if (entries[i].isIntersecting) {
          let products_info = entries[i].target.dataset.productImpressionsPaginationInfo

          if (products_info != undefined) {
            products_info = JSON.parse(products_info)
            const product_list_name =
              entries[i].target.dataset.eecListNamePagination == undefined
                ? $('.hotels-info-with-filtering.mb-6.with-nav.js-webpack-block').data('eec-list-name-pagination')
                : entries[i].target.dataset.eecListNamePagination
            utagLinkProductImpressionGeneric(products_info, products_info['product_page_number'], product_list_name)
          }

          productImpressionObserver.unobserve(entries[i].target)
        }
      }
    },
    { threshold: [0] }
  )

  const promotionImpressionObserver = new IntersectionObserver(
    function (entries) {
      for (let i = 0; i < entries.length; ++i) {
        if (entries[i].isIntersecting) {
          let promotion_impression_info = entries[i].target.dataset.utagEccPromoList

          if (promotion_impression_info) {
            promotion_impression_info = JSON.parse(promotion_impression_info)
            utagLinkPromotionImpressionInfo(promotion_impression_info)
          }

          promotionImpressionObserver.unobserve(entries[i].target)
        }
      }
    },
    { threshold: [0] }
  )

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // -----------------------------------------------------------GENERIC IMPRESSION -------------------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function default_product_impression() {
    const product_impresion = {
      product_impression_id: [],
      product_impression_name: [],
      product_impression_brand: [],
      product_impression_variant: [],
      product_impression_category: [],
      product_impression_list: [],
      product_impression_price: [],
      product_impression_position: [],
    }
    return product_impresion
  }

  function default_product_click() {
    const product_click = {
      product_id: '',
      product_name: '',
      product_category: '',
      product_variant: [],
      product_brand: '',
      product_unit_price: '',
      product_action_list: '',
      product_position: [],
    }
    return product_click
  }

  function default_promotion_impression() {
    const promotion_impresion = {
      promotion_id: [],
      promotion_name: [],
      promotion_creative: [],
      promotion_position: [],
    }
    return promotion_impresion
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // ----------------------------------------------------------- PROMOTION IMPRESSION OBSERVER -------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function promotionImpressionObserverInfo() {
    let promotion_info_viewed = false
    if (
      !$('.current .js-cat-filter').length &&
      $('.current .js-cat-filter').data('utag-eec') === undefined &&
      !$('.swiper-slide.current').length
    )
      return

    const promotion_impresion_observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true && !promotion_info_viewed) {
          promotion_info_viewed = true
          const promotion_impression_info = $('.current .js-cat-filter').data('utag-eec')
          utagLinkPromotionImpressionInfo(promotion_impression_info)
        }
      },
      { threshold: [0] }
    )
    promotion_impresion_observer.observe(document.querySelector('.wrapper.utag'))
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------ RECOMMENDED PROMOTION IMPRESSION ---------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function recommendedPromotionImpressionObserverInfo() {
    let promotion_info_viewed = false
    if (!$('.recommended_offer_list[data-utag-eec]').length) return
    const promotion_impresion_observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true && !promotion_info_viewed) {
          promotion_info_viewed = true
          const promotion_impression_info = $('.recommended_offer_list[data-utag-eec]').data('utag-eec')
          utagLinkPromotionImpressionInfo(promotion_impression_info)
        }
      },
      { threshold: [0] }
    )
    promotion_impresion_observer.observe(document.querySelector('.recommended_offer_list[data-utag-eec]'))
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // ----------------------------------------------------------- UTAG PROMOTION IMPRESSION ------------------------------------------------------------ //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function utagLinkPromotionImpressionInfo(promotion_impression_info) {
    const promotion_impression = default_promotion_impression()
    promotion_impression['promotion_id'] = promotion_impression_info['promotion_id']
    promotion_impression['promotion_name'] = promotion_impression_info['promotion_name']
    promotion_impression['promotion_creative'] = promotion_impression_info['promotion_creative']
    promotion_impression['promotion_position'] = promotion_impression_info['promotion_position']

    utag_link_promotion_impression(promotion_impression)
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------ MENU PROMOTION IMPRESSION OBSERVER -------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function menuPromotionImpressionObserverInfo() {
    if (
      !$('.current .js-cat-filter').length &&
      $('.current .js-cat-filter').data('utag-eec') === undefined &&
      !$('.swiper-slide.current').length &&
      initialization
    ) {
      return
    }

    const menu_promotion_observer = new MutationObserver(function () {
      if ($('.swiper-slide.current').length && $('.current .js-cat-filter').length && !initialization) {
        const promotion_impression_info = $('.current .js-cat-filter').data('utag-eec')
        utagLinkPromotionImpressionInfo(promotion_impression_info)
      }
    })
    menu_promotion_observer.observe(document.querySelector('.swiper-wrapper'), { attributes: true, subtree: true })
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // ----------------------------------------------------------- UTAG PROMOTION IMPRESSION ------------------------------------------------------------ //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function utag_link_promotion_impression(promotion_impression_info) {
    const merged = {}
    const base_info = {
      event_name: 'view_promotion',
      event_cat: 'ecommerce',
      event_act: 'promotion impression list',
      event_purpose: 'booking',
      event_structure: 'impression',
      event_lbl: '',
      non_interaction: 1,
    }
    base_info['enh_action'] = 'impression list'
    $.extend(merged, base_info, promotion_impression_info)
    if (merged['non_interaction'] == 1) {
      // Initilize new visitor session id simulting GA sessions
      IB.VisitorSessionId.initNewVisitorSessionId()
    }
    sendUtagEvent({ data: merged })
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // ----------------------------------------------------------- UTAG PRODUCT IMPRESSION--------------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function utag_link_product_impression(
    product_impression_info,
    event_action,
    non_interaction,
    list_title,
    event_structure = 'body'
  ) {
    try {
      const merged = {}
      let event_name
      let structure = event_structure

      if (event_action === 'product_click') {
        event_name = 'select_item'
      } else if (event_action === 'promo_click') {
        event_name = 'select_promotion'
      } else if (event_action === 'product impression list') {
        event_name = 'view_item_list'
        structure = 'impression'
      } else if (event_action === 'promotion impression list') {
        event_name = 'view_promotion'
      }

      const base_info = {
        ...(event_name ? { event_name } : {}),
        event_cat: 'ecommerce',
        event_act: event_action.replace('_', ' '),
        event_lbl:
          event_action === 'product_click'
            ? product_impression_info['product_name']
            : `${utag_data['page_section']}|${utag_data['page_type']}|${list_title}`,
        event_purpose: 'booking',
        event_structure: structure,
      }
      base_info['enh_action'] = event_action
      if (non_interaction == true) {
        base_info['non_interaction'] = 1
      }
      $.extend(merged, base_info, product_impression_info)
      if (merged['non_interaction'] == 1) {
        // Initilize new visitor session id simulting GA sessions
        IB.VisitorSessionId.initNewVisitorSessionId()
      }
      sendUtagEvent({ data: merged })
    } catch (err) {}
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // --------------------------------------------------------- PRODUCT IMPRESSION CONSTRUCTOR --------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function productImpressionConstructor(
    custom_product_impression,
    custom_impression_data,
    custom_product_impresion_list,
    custom_product_impresion_price,
    page
  ) {
    custom_product_impresion_price =
      custom_product_impresion_price == ''
        ? custom_impression_data['product_impression_price']
        : custom_product_impresion_price

    custom_product_impression['product_impression_id'] = custom_impression_data['product_impression_id']
    custom_product_impression['product_impression_name'] = custom_impression_data['product_impression_name']
    custom_product_impression['product_impression_brand'] = custom_impression_data['product_impression_brand']
    custom_product_impression['product_impression_variant'] = custom_impression_data['product_impression_variant']
    custom_product_impression['product_impression_category'] = custom_impression_data['product_impression_category']
    custom_product_impression['product_impression_list'] = custom_product_impresion_list
    custom_product_impression['product_impression_price'] = custom_product_impresion_price
    custom_product_impression['product_impression_position'] = custom_impression_data['product_impression_position']
    if (page) custom_product_impression['product_page_number'] = page
    custom_product_impression['hotel_short_name'] = custom_impression_data['hotel_short_name']

    return custom_product_impression
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // --------------------------------------------------------- ADVANCED PRODUCT IMPRESSION  ----------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function advancedProductImpression(custom_product_impression, custom_product_category) {
    // New list variables
    custom_product_impression['list_recommender_category'] = 'default'
    custom_product_impression['list_recommender_algorithm'] = 'default'

    // New product variables
    custom_product_impression['product_recommender_category'] = Array.from(
      { length: Array.isArray(custom_product_category) ? custom_product_category.length : 1 },
      () => 'default'
    )
    custom_product_impression['product_recommender_algorithm'] = Array.from(
      { length: Array.isArray(custom_product_category) ? custom_product_category.length : 1 },
      () => 'default'
    )
    custom_product_impression['product_recommender_new'] = Array.from(
      { length: Array.isArray(custom_product_category) ? custom_product_category.length : 1 },
      () => 'false'
    )
    custom_product_impression['product_recommender_conviction'] = Array.from(
      { length: Array.isArray(custom_product_category) ? custom_product_category.length : 1 },
      () => ''
    )
    return custom_product_impression
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // -----------------------------------------------------------IMPRESSION PAGINATION ----------------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function utagLinkProductImpressionPagination(pagination_info, page) {
    let product_impresion = default_product_impression()
    const eec_list_name_pagination = $('.hotels-info-with-filtering.with-nav').data('eec-list-name-pagination')
    const product_impression_list = []
    for (var i = 0; i < pagination_info['product_impression_id'].length; i++) {
      product_impression_list.push(eec_list_name_pagination)
    }

    product_impresion = productImpressionConstructor(
      product_impresion,
      pagination_info,
      product_impression_list,
      '',
      page
    )
    product_impresion = advancedProductImpression(product_impresion, pagination_info['product_impression_name'])

    utag_link_product_impression(
      product_impresion,
      pagination_info['event_act'],
      pagination_info['non_interaction'],
      eec_list_name_pagination
    )
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // ---------------------------------------------------------- IMPRESSION PAGINATION SEARCH ---------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function utagLinkProductImpressionPaginationSearch(pagination_info, page) {
    let product_impresion = default_product_impression()

    const elements = $('.hotel-list .hotel, .list-view .hotel, #VUE_APP .hotel-list .hotel')

    const product_impression_price = []
    elements.each(function (pos) {
      const data_price = $(this).find('.card-container').find('.call-to-action').data('price')
      const price = data_price === undefined || data_price === '' ? '' : parseInt(data_price)

      product_impression_price.push(price)
    })

    const product_impression_list = []
    for (var i = 0; i < pagination_info['product_impression_id'].length; i++) {
      product_impression_list.push('search|booking results|hotel list')
    }

    product_impresion = productImpressionConstructor(
      product_impresion,
      pagination_info,
      product_impression_list,
      product_impression_price,
      page
    )
    product_impresion = advancedProductImpression(product_impresion, pagination_info['product_impression_name'])

    utag_link_product_impression(
      product_impresion,
      pagination_info['event_act'],
      pagination_info['non_interaction'],
      'hotel list'
    )
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------- GENERIC IMPRESSION ----------------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function utagLinkProductImpressionGeneric(products_info, page, eec_list_name_pagination) {
    let product_impresion = default_product_impression()
    const product_impression_list = products_info['product_impression_list'] || []

    if (product_impression_list.length === 0 && eec_list_name_pagination) {
      for (var i = 0; i < products_info['product_impression_id'].length; i++) {
        product_impression_list.push(eec_list_name_pagination)
      }
    }

    product_impresion = productImpressionConstructor(
      product_impresion,
      products_info,
      product_impression_list,
      '',
      page
    )
    product_impresion = advancedProductImpression(product_impresion, products_info['product_impression_name'])

    const list_name_array = eec_list_name_pagination
      ? eec_list_name_pagination.split('|')
      : product_impression_list[0].split('|')
    const list_name = list_name_array[list_name_array.length - 1]

    utag_link_product_impression(
      product_impresion,
      products_info['event_act'],
      products_info['non_interaction'],
      list_name
    )
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // ----------------------------------------------------------- WAIT FOR UTAG DATA ------------------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function eecWaitForUtagData(eec_info, callback) {
    if (typeof utag !== 'undefined') {
      !callback ?? callback()
      utag.link(eec_info)
    } else {
      setTimeout(function () {
        eecWaitForUtagData(eec_info, callback)
      }, 500)
    }
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // ----------------------------------------------------------- PRODUCT IMPRESSION ------------------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function product_impresion_event(product_impresion, data_eec_product, event_lbl) {
    const price = data_eec_product.product_impression_price == 99999 ? '' : data_eec_product.product_impression_price

    const product_impression_list =
      $('#VUE_APP').length == 1
        ? 'destination | home destinations| hotel list'
        : data_eec_product.product_impression_list
    product_impresion['product_id'] = data_eec_product.product_impression_id
    product_impresion['product_name'] = data_eec_product.product_impression_name
    product_impresion['product_brand'] = data_eec_product.product_impression_brand
    product_impresion['product_variant'].push(data_eec_product.product_impression_variant)
    product_impresion['product_category'] = data_eec_product.product_impression_category
    product_impresion['product_action_list'] = product_impression_list
    product_impresion['product_unit_price'] = String(price)
    product_impresion['product_position'].push(data_eec_product.product_impression_position)
    product_impresion['hotel_short_name'] = data_eec_product.hotel_short_name

    product_impresion = advancedProductImpression(product_impresion, data_eec_product.product_impression_category)
    utag_link_product_impression(product_impresion, 'product_click', false, 'hotel list')
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // ----------------------------------------------------------- PROMOTION IMPRESSION ----------------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function promotion_impresion_event(promotion_impression, data_eec_product) {
    const creative_array = data_eec_product.promotion_creative.split('|')

    promotion_impression['promotion_id'].push(data_eec_product.promotion_id)
    promotion_impression['promotion_name'].push(data_eec_product.promotion_name)
    promotion_impression['promotion_creative'].push(data_eec_product.promotion_creative)
    promotion_impression['promotion_position'].push(data_eec_product.promotion_position)

    utag_link_product_impression(promotion_impression, 'promo_click', false, creative_array[creative_array.length - 1])
  }

  function promotion_click_event(promotion_impression, data_eec_product, event_structure) {
    const creative_array = data_eec_product.promotion_creative.split('|')

    promotion_impression['promotion_id'].push(data_eec_product.promotion_id)
    promotion_impression['promotion_name'].push(data_eec_product.promotion_name)
    promotion_impression['promotion_creative'].push(data_eec_product.promotion_creative)
    promotion_impression['promotion_position'].push(data_eec_product.promotion_position)

    promotion_impression['event_lbl'] = data_eec_product.promotion_name

    utag_link_product_impression(
      promotion_impression,
      'promo_click',
      false,
      creative_array[creative_array.length - 1],
      event_structure
    )
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // ----------------------------------------------------------- PRODUCT IMPRESSION SEARCH ------------------------------------------------------------ //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function product_impressions_search() {
    let product_impresion = default_product_impression()
    const elements = $('.hotel-list .hotel, .list-view .hotel, #VUE_APP .hotel-list .hotel')
    const MaxProduct = 20

    elements.each(function (pos) {
      const data_eec_product = $(this).data('utag-eec')
      const price_vue = $('#VUE_APP').length == 1 ? data_eec_product.product_impression_price : ''

      data_eec_product.product_impression_price = $(this).parent('.result-hotel').data('price')
      data_eec_product.product_impression_position = pos + 1

      const price = data_eec_product.product_impression_price == 99999 ? '' : data_eec_product.product_impression_price

      $(this).attr('data-utag-eec', JSON.stringify(data_eec_product))
      $(this)
        .find('.info-link')
        .each(function () {
          $(this).attr('data-utag-price', price)
          $(this).attr('data-utag-position', pos)
        })

      const product_impresion_list_name =
        $('#VUE_APP').length == 1
          ? `${utag_data['page_section']} | ${utag_data['page_type']}| ` + `hotel list`
          : 'search|booking results|hotel list'

      product_impresion['product_impression_id'].push(data_eec_product.product_impression_id)
      product_impresion['product_impression_name'].push(data_eec_product.product_impression_name)
      product_impresion['product_impression_brand'].push(data_eec_product.product_impression_brand)
      product_impresion['product_impression_variant'].push(data_eec_product.product_impression_variant)
      product_impresion['product_impression_category'].push(data_eec_product.product_impression_category)
      product_impresion['product_impression_position'].push(data_eec_product.product_impression_position)

      if ($('#VUE_APP').length == 1) {
        product_impresion['product_impression_list'].push(product_impresion_list_name)
        product_impresion['product_impression_price'].push(price_vue)
      } else {
        product_impresion['product_impression_list'].push(data_eec_product.product_impression_list)
        product_impresion['product_impression_price'].push(price)
      }

      product_impresion['hotel_short_name'] = data_eec_product.hotel_short_name

      product_impresion = advancedProductImpression(product_impresion, data_eec_product.product_impression_category)

      if ((pos + 1) % MaxProduct == 0 || pos + 1 == elements.length) {
        utag_link_product_impression(product_impresion, 'product impression list', true, 'hotel list')
        product_impresion = default_product_impression()
      }
    })
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // -----------------------------------------------------------PROMOTION IMPRESSION SLIDER ----------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function promotion_impressions_slider($el) {
    $el.each(function () {
      if (!$(this).hasClass('utag_promotion_sent') && $(this).is(':visible')) {
        const promotion_impression = default_promotion_impression()
        const data_eec_product = $(this).data('utag-eec')
        $(this).addClass('utag_promotion_sent')
        promotion_impresion_event(promotion_impression, data_eec_product)
      }
    })
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // ----------------------------------------------------------- INITIALIZE OBSERVERS ----------------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function initializeObservers() {
    const best_offers_blocks = document.querySelectorAll('.best-offers-block')
    const hotels_info_with_filtering = document.querySelectorAll('.wrapper.hotels-info-with-filtering')
    const hotel_list = document.querySelectorAll('.block.hotel-list.js-webpack-block')
    const banners_grid = document.querySelectorAll('.banners-grid')
    const automatic_offer_list = document.querySelectorAll('.automatic-offer-list.js-webpack-block')

    const show_map_tab_filtering = hotels_info_with_filtering.length
      ? hotels_info_with_filtering[0].dataset.showMapTab
      : null
    const show_map_tab_hotel_list = hotel_list.length ? hotel_list[0].dataset.showMapTab : null

    if (best_offers_blocks.length) {
      for (let i = 0; i < best_offers_blocks.length; ++i) {
        productImpressionObserver.observe(best_offers_blocks[i])
      }
    }

    if (hotels_info_with_filtering.length && show_map_tab_filtering !== TAB_INDEX_MAP_SHOWN_BY_DEFAULT) {
      for (let i = 0; i < hotels_info_with_filtering.length; ++i) {
        productImpressionObserver.observe(hotels_info_with_filtering[i])
      }
    }

    if (automatic_offer_list.length) {
      for (let i = 0; i < automatic_offer_list.length; ++i) {
        productImpressionObserver.observe(automatic_offer_list[i])
      }
    }

    if (hotel_list.length && show_map_tab_hotel_list !== TAB_INDEX_MAP_SHOWN_BY_DEFAULT) {
      for (let i = 0; i < hotel_list.length; ++i) {
        productImpressionObserver.observe(hotel_list[i])
      }
    }

    if (banners_grid.length) {
      for (let i = 0; i < banners_grid.length; ++i) {
        promotionImpressionObserver.observe(banners_grid[i])
      }
    }
  }

  // ----------------------------------
  // PRODUCT IMPRESION LIST MAP
  // -----------------------------------
  function getEventLabelImpressionProductList() {
    const el = document.querySelector('div[data-eec-list-name-pagination]')
    return el && el.dataset ? el.dataset.eecListNamePagination : ''
  }

  function generate_impression_product_list_object(all_hotels_data, default_product_impression_list = '') {
    const default_product = default_product_impression()
    let product_action_list = {
      event_name: 'view_item_list',
      event_cat: 'ecommerce',
      event_act: 'product impression list',
      event_lbl: getEventLabelImpressionProductList(),
      enh_action: 'product impression list',
      event_purpose: 'booking',
      event_structure: 'impression',
      non_interaction: 1,
      hotel_short_name: [],
      ...default_product,
    }

    if (all_hotels_data && all_hotels_data.length) {
      all_hotels_data.map(hotel => {
        const data_utag_eec = JSON.parse(hotel.data.utag_data_eec)
        product_action_list.product_impression_id.push(data_utag_eec.product_impression_id)
        product_action_list.product_impression_name.push(data_utag_eec.product_impression_name)
        product_action_list.product_impression_brand.push(data_utag_eec.product_impression_brand)
        product_action_list.product_impression_variant.push(data_utag_eec.product_impression_variant)
        product_action_list.product_impression_category.push(data_utag_eec.product_impression_category)
        if (data_utag_eec.product_impression_list == null || data_utag_eec.product_impression_list == '') {
          product_action_list.product_impression_list.push(default_product_impression_list)
        } else {
          product_action_list.product_impression_list.push(data_utag_eec.product_impression_list)
        }
        product_action_list.product_impression_price.push(data_utag_eec.product_impression_price)
        product_action_list.product_impression_position.push(data_utag_eec.product_impression_position)
        product_action_list.hotel_short_name.push(hotel.data.abrev)
      })
    }

    product_action_list = advancedProductImpression(product_action_list, product_action_list['product_impression_name'])
    sendUtagEvent({ data: product_action_list })
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------- //
  // --------------------------------------------------------------------- INITIALIZE ALL ------------------------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------------------------------------------------- //

  function init() {
    $(document).on(
      'click',
      '.hotel .btn-floating-fastbooking, .result-hotel .btn-with-arrow, a.utag-product-link, a.utag-recommended-hotel, .new-hotel a',
      function () {
        const product_impresion = default_product_click()
        const data_eec_product =
          $(this).data('utag-eec') === undefined ? $(this).closest('.hotel').data('utag-eec') : $(this).data('utag-eec')
        const page_current = $('.js-paginate.current')

        if (page_current != '' || page_current != undefined) {
          product_impresion['product_page_number'] = page_current.html()
        }

        product_impresion_event(product_impresion, data_eec_product, '')
      }
    )

    $(document).on('click', '.apply-filters.secondary-btn.mobile-only', function () {
      const data_eec_promotion = $('.js-offer-filter-mobile .current .js-cat-filter').data('utag-eec')

      utag_link_promotion_impression(data_eec_promotion)
    })

    $(document).on('click', '.swiper-slide:not(.current)', function () {
      if (
        !$('.current .js-cat-filter').length &&
        $('.current .js-cat-filter').data('utag-eec') === undefined &&
        !$('.swiper-slide.current').length
      ) {
        return
      }
      if ($('.swiper-slide.current').length && $('.current .js-cat-filter').length) {
        const promotion_impression_info = $('.current .js-cat-filter').data('utag-eec')
        utagLinkPromotionImpressionInfo(promotion_impression_info)
      }
    })

    $(document).on(
      'click',
      '.offer .js-book-btn, .offer-fancybox .wrapper .offer-button .js-book-btn, .b-offer-module-list .b-offer-module a.btn-with-arrow, a.utag-promo-link, a.utag-offer-view, .offer-block',
      function () {
        const promotion_impression = default_promotion_impression()
        const event_structure = $(this).data('utag-event-structure') || 'body'
        let data_eec_product =
          $(this).data('ecc-product') === undefined ? $(this).data('ecc-promo') : $(this).data('ecc-product')
        data_eec_product = data_eec_product ? data_eec_product : $(this).parent().data('utag-eec')
        promotion_click_event(promotion_impression, data_eec_product, event_structure)
      }
    )

    initializeObservers()
  }

  window.IB.utag_eec_events = {
    init,
    product_impressions_search,
    promotion_impressions_slider,
    eecWaitForUtagData,
    utagLinkProductImpressionPagination,
    utagLinkProductImpressionPaginationSearch,
    promotionImpressionObserverInfo,
    recommendedPromotionImpressionObserverInfo,
    menuPromotionImpressionObserverInfo,
    promotionImpressionObserver,
    productImpressionObserver,
    generate_impression_product_list_object,
  }
})(jQuery)

$(function () {
  IB.utag_eec_events.init()
  IB.utag_eec_events.promotionImpressionObserverInfo()
  IB.utag_eec_events.menuPromotionImpressionObserverInfo()
})
